/* eslint-disable no-alert */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Loader from '../../Components/Loader';
import { bindActionCreators } from 'redux';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { connect } from 'react-redux';
import Map from '../../Components/MapWithState';
import { getAllZones, deleteFieldRoutes } from '../../state/zones';
import { getScheduledRoutes, deleteScheduledRoute } from '../../state/scheduled_routes';
import { getFieldRoutes, deleteFieldRoute, getFieldRouteById } from '../../state/field_routes';
import { getFieldRouteTickets } from '../../state/field_route_tickets';
import { createFieldTickets } from '../../state/routes';
import { getFieldRouteStatuses } from '../../state/field_route_tickets_statuses';
import { setSelectedZone } from '../../state/selected_zone';
import { DropdownList } from 'react-widgets';
import { getOpsUsers, getRoutedOps } from '../../state/users';
import ActiveRoutes from '../../Components/ActiveRoutes';
import UnassignedRoutes from '../../Components/UnassignedRoutes';
import ScheduledRoutes from '../../Components/ScheduledRoutes';
import RouteTicketsTable from '../../Components/RouteTicketsTable';
import { getActiveZones } from '../../util/helper';
import { ACTIVE_ROUTE_MAP, UNASSIGNED_ROUTE_MAP, ROUTE_TICKETS } from '../../util/constants';
import close from '../../images/close.png';
import momentLocalizer from 'react-widgets-moment';
import moment from 'moment-timezone';

moment.locale('en');
momentLocalizer();

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      selectedZone: null,
      error: null,
      zones: [],
      open: false,
      active_field_routes: [],
      scheduled_routes: [],
      unassigned_field_routes: [],
      active_routes: [],
      selectedRouteTickets: [],
      popupType: null,
      mapPlaces: [],
      mapCenter: {},
      routePlaces: [],
      selectedRoute: null,
      showRouteAssignment: false,
      showUnassignedRoutes: false,
      showActiveRoutes: false,
      showScheduledRoutes: false,
      showZoneSelect: true,
      showDeleteBtn: false,
      opsUsers: [],
      selectedOps: [],
      selectedFieldRouteId: null,
      hasActiveRoutes: false,
      hasUnassignedRoutes: false,
      hasScheduledRoute: false,
      linkedZone: null,
      activeZones: [],
      routeDeleted: false,
    };
    this.props.actions.getAllZones().then(() => {
      const active_zones = getActiveZones(this.props.zones, this.props.admin.user_default_zones);
      this.setState({ activeZones: active_zones });
      if (this.props.selected_zone) {
        const selectedZone = this.props.zones.find((zone) => zone.id === this.props.selected_zone);
        this.setState({ selectedZone });
        const callType = 'zone_state';
        this.handleZoneChange(selectedZone, callType);
      } else {
        this.setState({
          loaded: true,
        });
      }
    });
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    const routeData = this.props.location;
    if (routeData.data && routeData.data.zone_id) {
      this.props.actions.getAllZones().then(() => {
        let activeZones = this.props.zones.filter((zone) => zone.active === true);
        activeZones.sort();
        this.setState({
          activeZones: activeZones,
        });
        this.handleZoneChange(this.props.selected_zone, 'zone_state');
      });
    }
  }

  handleZoneChange = async (val, callType) => {
    try {
      let selectedZone = {};
      if (callType === 'zone_state') {
        //This is being called because a zone is saved as a state var, not because of a change in the dropdown select
        const filteredZone = this.state.activeZones.filter(
          (zone) => zone.id === this.props.selected_zone
        );
        selectedZone = filteredZone[0];
      } else {
        if (typeof val === 'object') {
          selectedZone = val;
        } else {
          const zone_id = this.props.zones.filter((zone) => zone.id === val);
          selectedZone = zone_id[0];
        }
        await this.props.actions.setSelectedZone(selectedZone.id);
      }
      this.setState({
        selectedZone: selectedZone,
        active_field_routes: null,
        unassigned_field_routes: null,
        showUnassignedRoutes: false,
        showActiveRoutes: false,
      });
      await this.props.actions.getRoutedOps(selectedZone.id);
      await this.props.actions.getOpsUsers(selectedZone.id);
      this.loadRoutes(selectedZone.id);
    } catch (error) {
      alert(error);
      this.setState({
        loaded: true,
      });
    }
  };

  closePopup = () => {
    this.setState({ open: false, selectedRide: '' });
  };

  openPopup = (val, type) => {
    switch (type) {
      case ACTIVE_ROUTE_MAP:
        this.openActiveMapPopup(val);
        break;
      case UNASSIGNED_ROUTE_MAP:
        this.openUnassignedMapPopup(val);
        break;
      default:
        this.setState({
          open: true,
        });
        break;
    }
  };

  openActiveMapPopup = async (field_route_id) => {
    await this.props.actions.getFieldRouteById(field_route_id).catch((error) => {
      if (window.confirm(error)) {
        window.location.reload();
      } else {
        window.location.reload();
      }
    });
    const mapPlaces = this.getMapPlaces();
    const routePlaces = this.getRoutePlaces();
    this.setState({
      mapCenter: mapPlaces[0].location,
      mapPlaces: mapPlaces,
      routePlaces: routePlaces,
      open: true,
      popupType: ACTIVE_ROUTE_MAP,
      loaded: true,
    });
  };

  openUnassignedMapPopup = async (field_route_id) => {
    this.setState({
      loaded: false,
    });
    await this.props.actions.getFieldRouteById(field_route_id).catch((error) => {
      if (window.confirm(error)) {
        window.location.reload();
      } else {
        window.location.reload();
      }
    });
    const mapPlaces = this.getMapPlaces();
    const routePlaces = this.getRoutePlaces();
    this.setState({
      mapCenter: mapPlaces[0].location,
      mapPlaces: mapPlaces,
      routePlaces: routePlaces,
      open: true,
      popupType: UNASSIGNED_ROUTE_MAP,
      loaded: true,
    });
  };

  getMapPlaces = () => {
    let mapPlaces = [];
    let routePlaces = [];
    this.props.field_route_tickets.forEach((route) => {
      const location = {
        lat: route.location.latitude,
        lng: route.location.longitude,
      };
      const status = route.vehicle.status;
      const vehicle_name = route.vehicle.name;
      const has_active_ticket = route.resolved_date !== null;
      const place = {
        location,
        status,
        vehicle_name,
        has_active_ticket,
      };
      mapPlaces.push(place);
      routePlaces.push({
        lat: route.location.latitude,
        lng: route.location.longitude,
      });
    });
    return mapPlaces;
  };

  getRoutePlaces = () => {
    let routePlaces = [];
    this.props.field_routes.forEach((route) => {
      routePlaces.push({
        lat: route.location.latitude,
        lng: route.location.longitude,
      });
    });
    return routePlaces;
  };

  openRoutePopup = (tickets) => {
    this.setState(
      {
        selectedRouteTickets: tickets,
        popupType: ROUTE_TICKETS,
        open: true,
      },
      this.loadPopup
    );
  };

  loadPopup = () => {
    const type = this.state.popupType;
    switch (type) {
      case ACTIVE_ROUTE_MAP:
        return (
          <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '600px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            center={this.state.mapCenter}
            zoom={12}
            places={this.state.mapPlaces}
            routePlaces={this.state.routePlaces}
          />
        );
      case UNASSIGNED_ROUTE_MAP:
        return (
          <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '600px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            center={this.state.mapCenter}
            zoom={12}
            places={this.state.mapPlaces}
            routePlaces={this.state.routePlaces}
          />
        );
      case ROUTE_TICKETS:
        return (
          <div className="modal">
            <div className="closeModalBtn" onClick={this.closeModal}>
              <img
                src={close}
                alt="Close Modal Button"
                onClick={this.closePopup}
                className=""
                style={{
                  paddingTop: '3px',
                  alignSelf: 'center',
                }}
              />
            </div>
            <div className="routeTicketsTable">{this.routeTicketsTable()}</div>
          </div>
        );
      default:
        return (
          <div className="modal">
            <div className="closeModalBtn" onClick={this.closeModal}>
              <img
                src={close}
                alt="Close Modal Button"
                onClick={this.closePopup}
                className=""
                style={{
                  paddingTop: '3px',
                  alignSelf: 'center',
                }}
              />
            </div>
            <div className="routeTicketsTable">{this.routeTicketsTable()}</div>
          </div>
        );
    }
  };

  displayDuration = (duration) => {
    const minutes = duration > 60 ? Math.ceil(duration / 60) : 1;
    return `${minutes} mins`;
  };

  deleteRoutes = async () => {
    this.setState({
      loaded: false,
    });
    await this.props.actions.deleteFieldRoutes(this.state.selectedZone.id);
    alert('Routes and associated tickets successfully deleted');
    this.setState({
      showDeleteBtn: false,
      loaded: true,
    });
  };

  deleteFieldRoute = async (field_route_id) => {
    const confirmation = window.confirm('Are you sure you want to cancel?');
    if (confirmation === true) {
      this.setState({
        loaded: false,
        hasActiveRoutes: false,
        routeDeleted: true,
      });
      await this.props.actions.deleteFieldRoute(field_route_id).catch((error) => {
        if (window.confirm(error)) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      });
      this.handleZoneChange(this.state.selectedZone);
    }
  };

  deleteScheduledRoute = async (scheduled_route_id) => {
    this.setState({
      loaded: false,
    });
    await this.props.actions.deleteScheduledRoute(scheduled_route_id).catch((error) => {
      if (window.confirm(error)) {
        window.location.reload();
      } else {
        window.location.reload();
      }
    });
    this.handleZoneChange(this.state.selectedZone);
  };

  displayStatuses = (statuses) => {
    return `${statuses}`;
  };

  undoFieldRouteSelection = () => {
    this.setState({
      selectedFieldRouteId: null,
      showZoneSelect: true,
      showUnassignedRoutes: true,
      showRouteAssignment: false,
      loaded: true,
    });
  };

  handleChangeOps = (value) => {
    this.setState((prevState) => ({
      selectedOps: [...prevState.selectedOps, value.id],
    }));
  };

  assignRoute = (field_route_id) => {
    this.setState({
      loaded: true,
      showRouteAssignment: true,
      selectedFieldRouteId: field_route_id,
    });
  };

  createFieldTickets = async () => {
    this.setState({
      loaded: false,
      showRouteAssignment: false,
    });
    if (this.state.selectedOps.length === 0) {
      alert('First select an ops user to assign to route');
    } else {
      const params = {
        field_route_id: this.state.selectedFieldRouteId,
        ops_users: this.state.selectedOps,
      };
      await this.props.actions.createFieldTickets(params);
      this.handleZoneChange(this.state.selectedZone);
    }
  };

  loadRoutes = async (selectedFieldRouteId) => {
    this.setState({
      loaded: false,
      showRouteAssignment: false,
      showActiveRoutes: false,
      showUnassignedRoutes: false,
    });
    let params = {
      zone_id: this.state.selectedZone.id,
    };
    // Get Field Routes
    await this.props.actions.getFieldRoutes(params).catch((error) => {
      if (window.confirm(error)) {
        window.location.reload();
      } else {
        window.location.reload();
      }
    });
    await this.loadUnassignedRoutes();
    await this.loadActiveRoutes(selectedFieldRouteId);
    await this.loadScheduledRoutes(this.state.selectedZone.id);
    const hasActiveRoutes = this.state.hasActiveRoutes;
    const hasScheduledRoute = this.state.hasScheduledRoute;
    const hasUnassignedRoutes = this.state.hasUnassignedRoutes;
    const routeDeleted = this.state.routeDeleted;
    if (hasActiveRoutes) {
      this.setState({ showActiveRoutes: true });
    }
    if (hasUnassignedRoutes) {
      this.setState({ showUnassignedRoutes: true });
    }
    if (hasScheduledRoute) {
      this.setState({ showScheduledRoutes: true });
    }
    if (!hasActiveRoutes && !hasUnassignedRoutes && !hasScheduledRoute && !routeDeleted) {
      this.setState({
        loaded: true,
      });
      alert('There are no routes created yet for this zone. Create a route first.');
    } else {
      this.setState({
        loaded: true,
        routeDeleted: false,
      });
    }
  };

  loadUnassignedRoutes = async () => {
    let unassigned_routes = [];
    unassigned_routes = await this.props.field_routes.filter(
      (route) => route.is_active === false && route.assigned_to === null
    );
    if (unassigned_routes.length > 0) {
      this.setState({
        unassigned_field_routes: unassigned_routes,
        hasUnassignedRoutes: true,
      });
    } else {
      this.setState({
        hasUnassignedRoutes: false,
      });
    }
  };

  loadScheduledRoutes = async (selectedZoneId) => {
    await this.props.actions.getScheduledRoutes(selectedZoneId);
    const scheduled_routes = [];
    await Promise.all(
      this.props.scheduled_routes.map(async (route) => {
        const assigned_to = this.props.opsUsers.filter((ops) => ops.id === route.assigned_to);
        const ops_name = assigned_to[0].first_name + ' ' + assigned_to[0].last_name;
        route.assigned_to_name = ops_name;
        scheduled_routes.push(route);
      })
    );
    if (this.props.scheduled_routes.length > 0) {
      this.setState({
        scheduled_routes: scheduled_routes,
        hasScheduledRoute: true,
      });
    }
  };

  loadActiveRoutes = async (selectedFieldRouteId) => {
    const active_field_routes = await this.getActiveRoutes(selectedFieldRouteId);
    if (active_field_routes.length > 0) {
      this.setState({
        active_field_routes: active_field_routes,
        hasActiveRoutes: true,
      });
    } else {
      this.setState({
        hasActiveRoutes: false,
      });
    }
  };

  getActiveRoutes = async (selectedFieldRouteId) => {
    let active_field_route = {};
    let routeEstimate = 0;
    let active_field_routes = [];
    await Promise.all(
      this.props.field_routes.map(async (route) => {
        if (route.is_active === true || route.id === selectedFieldRouteId) {
          await this.props.actions.getFieldRouteStatuses(route.id);
          const statuses = this.props.field_route_tickets_statuses;
          const status = statuses.join();
          route.statuses = status;
          // get tickets for route and add to object
          await this.props.actions.getFieldRouteTickets(route.id);
          // get route duration estimate as a sum of all the ticket estimates
          let ecoCnt = 0;
          let s1Cnt = 0;
          let ddCnt = 0;
          this.props.field_route_tickets.forEach((ticket) => {
            // build route estimate based on ticket estimates
            routeEstimate = routeEstimate + ticket.estimated_time;
            route.duration = routeEstimate;
            // build vehicle type
            const vehicle_type = ticket.vehicle.vehicle_type;
            const battery_type = ticket.vehicle.battery_type;
            if (vehicle_type === 1 || vehicle_type === 5) {
              s1Cnt++;
            }
            if ((vehicle_type === 2 || vehicle_type === 3) && battery_type !== 4) {
              ecoCnt++;
            }
            if (battery_type === 4 || vehicle_type === 4) {
              ddCnt++;
            }
          });
          route.vehicle_types = ecoCnt + ' Eco, ' + s1Cnt + ' S1, ' + ddCnt + ' DD ';
          const ticketCnt = this.props.field_route_tickets.length;
          const resolvedTickets = this.props.field_route_tickets.filter(
            (ticket) => ticket.resolved_date !== null
          );
          const resolvedTicketsCnt = resolvedTickets.length;
          const routeCntDisplay = resolvedTicketsCnt + ' of ' + ticketCnt;
          route.progress = routeCntDisplay;
          route.tickets = this.props.field_route_tickets;
          const first_name = route.tickets[0].assigned_to.first_name;
          const last_name = route.tickets[0].assigned_to.last_name;
          route.name = first_name + ' ' + last_name;
          route.status = route.tickets[0].status;
          active_field_route = route;
          active_field_routes.push(active_field_route);
        }
        route.duration = routeEstimate;
      })
    );
    return active_field_routes;
  };

  routeTicketsTable = () => {
    const routeTableData = this.state.selectedRouteTickets.map((ticket) => {
      const vehicle_type = ticket.vehicle.vehicle_type;
      const battery_type = ticket.vehicle.battery_type;
      let vehicleTypeName = '';
      if ((vehicle_type === 1 || vehicle_type === 5) && battery_type !== 4) {
        vehicleTypeName = 'S1';
      }
      if ((vehicle_type === 2 || vehicle_type === 3) && battery_type !== 4) {
        vehicleTypeName = 'Eco';
      }
      if (battery_type === 4 || vehicle_type === 4) {
        vehicleTypeName = 'Double-double';
      }
      ticket.vehicle_type_name = vehicleTypeName;
      if (ticket.resolved_date === null) {
        ticket.resolution = 'Open';
      } else {
        ticket.resolution = 'Completed';
      }
      if (ticket.vehicle.status === 'low_battery' || ticket.vehicle.status === 'available') {
        ticket.service_type = 'Swap';
      } else {
        ticket.service_type = 'Pickup';
      }
      return ticket;
    });
    return <RouteTicketsTable tickets={routeTableData} />;
  };

  UnassignedRoutesTable = () => {
    return (
      <UnassignedRoutes
        onClick={this.deleteFieldRoute}
        openPopup={this.openPopup}
        assignRoute={this.assignRoute}
        unassignedFieldRoutes={this.state.unassigned_field_routes}
        timeZone={this.state.selectedZone.time_zone_name}
      />
    );
  };

  activeRoutesTable = () => {
    return (
      <ActiveRoutes
        openPopup={this.openPopup}
        deleteFieldRoute={this.deleteFieldRoute}
        openRoutePopup={this.openRoutePopup}
        active_field_routes={this.state.active_field_routes}
        timeZone={this.state.selectedZone.time_zone_name}
      />
    );
  };

  scheduledRoutesTable = () => {
    return (
      <ScheduledRoutes
        openPopup={this.openPopup}
        deleteScheduledRoute={this.deleteScheduledRoute}
        timeZone={this.state.selectedZone.time_zone_name}
        scheduled_routes={this.props.scheduled_routes}
      />
    );
  };

  render() {
    const {
      selectedZone,
      showZoneSelect,
      showActiveRoutes,
      showUnassignedRoutes,
      showScheduledRoutes,
      showRouteAssignment,
      showDeleteBtn,
      selectedOps,
    } = this.state;
    const popup = this.loadPopup;
    return (
      <AuthenticatedPage className="edit_vehicle_container">
        <div className="page-title">Manage Routes</div>
        {showZoneSelect && (
          <Fragment>
            <h3>Select Zone</h3>
            <DropdownList
              className="vehicles_container__select"
              data={this.state.activeZones}
              value={selectedZone}
              valueField="id"
              textField="name"
              onChange={this.handleZoneChange}
              placeholder="Select a Zone"
            />
          </Fragment>
        )}
        {showUnassignedRoutes && (
          <Fragment>
            <div className="unassigned_route_table_header">
              <h3>Unassigned Routes</h3>
            </div>
            <div className="routeTable">{this.UnassignedRoutesTable(this.props.field_routes)}</div>
          </Fragment>
        )}
        {showRouteAssignment && (
          <Fragment>
            <h3>Select fields ops for route # {this.state.selectedFieldRouteId} </h3>
            <DropdownList
              className="vehicles_container__select"
              data={this.props.opsUsers}
              value={selectedOps[0]}
              valueField="id"
              textField="fullname"
              onChange={this.handleChangeOps}
              placeholder="Select a field ops employee"
            />
            <input
              type="button"
              value="Create Field Tickets"
              onClick={this.createFieldTickets}
              className="create_field_tickets_btn"
            />
          </Fragment>
        )}
        {showActiveRoutes && (
          <Fragment>
            <div className="active_route_table_header">
              <h3>Active Routes</h3>
            </div>
            <div className="routeTable">{this.activeRoutesTable(this.props.field_routes)}</div>
          </Fragment>
        )}
        {showDeleteBtn && this.props.field_routes.length > 0 && (
          <input
            type="button"
            value="Delete All"
            onClick={this.deleteRoutes}
            className="reset_btn"
          />
        )}
        {showScheduledRoutes && (
          <Fragment>
            <div className="active_route_table_header">
              <h3>Scheduled Routes</h3>
            </div>
            <div className="routeTable">
              {this.scheduledRoutesTable(this.props.scheduled_routes)}
            </div>
          </Fragment>
        )}
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closePopup}
          overlayStyle={{ backgroundColor: 'light-grey' }}
          contentStyle={{ maxHeight: '100vh', overflowY: 'auto' }}>
          {popup}
        </Popup>
        <Loader loaded={this.state.loaded} />
      </AuthenticatedPage>
    );
  }
}

Routes.propTypes = {
  actions: PropTypes.object,
  admin: PropTypes.object,
  field_route_tickets_statuses: PropTypes.array,
  field_route_tickets: PropTypes.array,
  field_routes: PropTypes.array,
  location: PropTypes.object,
  opsUsers: PropTypes.array,
  scheduled_routes: PropTypes.array,
  selected_zone: PropTypes.object,
  zones: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    zones: { zones },
    admin,
    field_routes,
    field_route_tickets,
    field_route_tickets_statuses,
    route,
    users,
    scheduled_routes,
    selected_zone,
  } = state;
  const restrictedAccess = admin && admin.restricted_access;
  return {
    admin,
    zones,
    restrictedAccess,
    field_routes,
    field_route_tickets,
    field_route_tickets_statuses,
    route,
    opsUsers: users.ops.filter(({ is_active }) => is_active),
    scheduled_routes,
    selected_zone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getActiveZones,
        getFieldRoutes,
        getFieldRouteStatuses,
        getAllZones,
        deleteFieldRoutes,
        getFieldRouteTickets,
        getOpsUsers,
        createFieldTickets,
        getFieldRouteById,
        deleteFieldRoute,
        getRoutedOps,
        getScheduledRoutes,
        deleteScheduledRoute,
        setSelectedZone,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
