import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';

const moment = require('moment-timezone');

export default class ScheduledRoutes extends Component {
  deleteScheduledRoute = (scheduled_route_id) => {
    this.props.deleteScheduledRoute(scheduled_route_id);
  };

  render() {
    const { scheduled_routes, timeZone } = this.props;
    return (
      <ReactTable
        ref={(r) => {
          this.selectTable = r;
        }}
        className="routes_table"
        data={scheduled_routes}
        pageSize={scheduled_routes.length}
        getTheadThProps={() => {
          return { style: { outline: 0 } };
        }}
        showPaginationBottom={false}
        columns={[
          {
            Header: 'Id',
            accessor: 'id',
            width: 50,
          },
          {
            Header: 'Assigned To',
            accessor: 'assigned_to_name',
            width: 150,
          },
          {
            Header: 'Start Time',
            accessor: 'shift_start_time',
            width: 200,
            Cell: (props) => (
              <Fragment>
                {props.value && moment(props.value).tz(timeZone).format('MMM DD YYYY, h:mm a')}
              </Fragment>
            ),
          },
          {
            Header: 'End Time',
            accessor: 'shift_end_time',
            width: 200,
            Cell: (props) => (
              <Fragment>
                {props.value && moment(props.value).tz(timeZone).format('MMM DD YYYY, h:mm a')}
              </Fragment>
            ),
          },
          /*
        {
          Header: 'Truck Capacity',
          accessor: 'truck_capacity',
        },

        {
          Header: 'Statuses',
          accessor: 'status',
        },
        */
          {
            Header: '',
            accessor: '',
            maxWidth: 300,
            Cell: (props) => (
              <div style={{ textAlign: 'center' }}>
                {
                  <input
                    type="button"
                    value="Cancel"
                    onClick={(event) => {
                      this.deleteScheduledRoute(props.original.id);
                      event.stopPropagation();
                    }}
                  />
                }
              </div>
            ),
          },
        ]}
        defaultSorted={[
          {
            id: 'created_date',
            desc: false,
          },
        ]}
      />
    );
  }
}
