//Popup Type Constants
export const INVOICE = "INVOICE"
export const REFUND = "REFUND"
export const SCOOTER_RIDE = "SCOOTER_RIDE"
export const RIDE_MAP = "RIDE_MAP"
export const ACTIVE_ROUTE_MAP = "ACTIVE_ROUTE_MAP"
export const ROUTE_TICKETS = "ROUTE_TICKETS"
export const UNASSIGNED_ROUTE_MAP = "UNASSIGNED_ROUTE_MAP"
export const ROUTE_SCHEDULER = "ROUTE_SCHEDULER"
export const RIDE_PHOTO = "RIDE_PHOTO"
export const LOW_BATTERY_DWELL_TIME = 3
export const DAMAGED_DWELL_TIME = 5
export const PICKUP_REQUESTED_DWELL_TIME = 5
export const ROUTE_VEHICLE_LIMIT = 60
export const MAX_ROUTE_TIME = 23
