import React, { Component } from 'react';
import ReactTable from 'react-table';
import { UNASSIGNED_ROUTE_MAP } from '../../util/constants';
import  map_btn from '../../images/map.png';

const moment = require('moment-timezone');

export default class Rides extends Component {

  openPopup = (val, type) => {
    this.props.openPopup(val, type);
  }

  assignRoute = (val) => {
    this.props.assignRoute (val);
  }
 

  render() {
    const { unassignedFieldRoutes, timeZone, onClick } = this.props;
    return (
      <ReactTable
        ref={r => {
          this.selectTable = r;
        }}
        className="routes_table"
        data={unassignedFieldRoutes}
        pageSize= {unassignedFieldRoutes.length}
        getTheadThProps={() => { return { style: { outline: 0, } }; }}
        showPaginationBottom={false}
        columns={[
            {
              Header: 'Route ID',
              accessor: 'id',
              width: 100,
              Cell: props => (
                <div style={{ textAlign: 'center' }}>
                  {props.original.id}
                </div>
              )
            },
            {
              Header: 'Start Time',
              accessor: 'start_time',
              width: 200,
              Cell: props => (
                <div style={{ textAlign: 'center' }}>
                  {props.original.start_time &&
                    moment(props.original.start_time)
                      .tz(timeZone)
                      .format('MMM DD YYYY, h:mm a')}
                </div>
              )
            },
            {
              Header: 'End Time',
              accessor: 'end_time',
              width: 200,
              Cell: props => (
                <div style={{ textAlign: 'center' }}>
                    {props.original.end_time &&
                    moment(props.original.end_time)
                      .tz(timeZone)
                      .format('MMM DD YYYY, h:mm a')}
                </div>
              )
            },
            {
              Header: 'Map',
              accessor: 'map',
              Cell: props =>
                <div style={{ textAlign: 'center' }}>
                  <img
                      src={map_btn}
                      alt="Close Modal Button"
                      onClick={(event) => {
                        this.openPopup(props.original.id, UNASSIGNED_ROUTE_MAP);
                        event.stopPropagation();
                      }}
                      className=""
                      style={{
                        paddingTop: '3px',
                        alignSelf: 'center',
                        height: '25px',
                        width: '25px',
                      }} 
                  />
                </div>
            },
            {
              Header: '',
              accessor: '',
              maxWidth: 300,
              Cell: props => (
                <div style={{ textAlign: 'center' }}>
                  {(
                      <input
                        type="button"
                        value="Assign"
                        onClick={event => {
                          this.assignRoute(props.original.id);
                          event.stopPropagation();
                        }}
                      />
                    )}
                </div>
              ),
            },
            {
              Header: '',
              accessor: '',
              maxWidth: 300,
              Cell: props => (
                <div style={{ textAlign: 'center' }}>
                  {(
                      <input
                        type="button"
                        value="Cancel"
                        onClick={event => {
                          onClick(props.original.id);
                          event.stopPropagation();
                        }}
                      />
                    )}
                </div>
              ),
            },
        ]}
        defaultSorted={[
          {
            id: 'created_date',
            desc: false,
          },
        ]}
      />
    );
  }
}
