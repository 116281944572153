import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import { displayDuration } from '../../util/helper';

export default class RouteTicketsTable extends Component {
  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.row.resolution === 'Open' ? 'white' : 'light-grey',
        },
      };
    }
    return {};
  };

  render() {
    const { tickets } = this.props;
    return (
      <ReactTable
        ref={(r) => {
          this.selectTable = r;
        }}
        onFilteredChange={this.onFilteredChange}
        className="-striped -highlight users_table user_table_add_on "
        data={tickets}
        showPaginationBottom={false}
        pageSize={tickets.length}
        getTrProps={this.getTrProps}
        width="100%"
        columns={[
          {
            Header: 'Vehicle',
            accessor: 'vehicle.name',
            maxWidth: 100,
          },
          {
            Header: 'Type',
            accessor: 'vehicle_type_name',
            maxWidth: 100,
          },
          {
            Header: 'Vehicle Status',
            accessor: 'vehicle.status',
            maxWidth: 100,
          },
          {
            Header: 'Service Type',
            accessor: 'service_type',
            maxWidth: 100,
          },
          {
            Header: 'Estimate',
            accessor: 'estimated_time',
            Cell: (props) => <Fragment>{displayDuration(props.value)}</Fragment>,
            maxWidth: 70,
          },
          {
            Header: 'Ticket Status',
            accessor: 'resolution',
            maxWidth: 100,
          },
        ]}
        defaultSorted={[
          {
            id: 'created_date',
            desc: false,
          },
        ]}
      />
    );
  }
}
