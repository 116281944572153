import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import { ACTIVE_ROUTE_MAP } from '../../util/constants';
import { displayDuration } from '../../util/helper';
import map_btn from '../../images/map.png';

const moment = require('moment-timezone');

export default class Rides extends Component {
  openPopup = (val, type) => {
    this.props.openPopup(val, type);
  };

  deleteFieldRoute = (field_route_id) => {
    this.props.deleteFieldRoute(field_route_id);
  };

  openRoutePopup = (tickets) => {
    this.props.openRoutePopup(tickets);
  };

  render() {
    const { active_field_routes, timeZone } = this.props;
    return (
      <ReactTable
        ref={(r) => {
          this.selectTable = r;
        }}
        className="routes_table"
        data={active_field_routes}
        showPaginationBottom={false}
        pageSize={active_field_routes.length}
        getTheadThProps={() => {
          return { style: { outline: 0 } };
        }}
        showPagination={true}
        columns={[
          {
            Header: 'Operator',
            accessor: 'name',
            width: 150,
          },
          {
            Header: 'Start Time',
            accessor: 'start_time',
            width: 200,
            Cell: (props) => (
              <Fragment>
                {props.value && moment(props.value).tz(timeZone).format('MMM DD YYYY, h:mm a')}
              </Fragment>
            ),
          },
          {
            Header: 'Vehicle Types',
            accessor: 'vehicle_types',
            width: 150,
          },
          {
            Header: 'Estimate',
            accessor: 'duration',
            Cell: (props) => <Fragment>{displayDuration(props.value)}</Fragment>,
          },
          {
            Header: 'Statuses',
            accessor: 'statuses',
          },
          {
            Header: 'Progress',
            accessor: 'progress',
            Cell: (props) => (
              <div
                onClick={(event) => {
                  this.openRoutePopup(props.original.tickets);
                  event.stopPropagation();
                }}>
                <span className="underline scooterLink">{props.value}</span>
              </div>
            ),
          },
          {
            Header: 'Map',
            accessor: 'map',
            Cell: (props) => (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={map_btn}
                  alt="Close Modal Button"
                  onClick={(event) => {
                    this.openPopup(props.original.id, ACTIVE_ROUTE_MAP);
                    event.stopPropagation();
                  }}
                  className=""
                  style={{
                    paddingTop: '3px',
                    alignSelf: 'center',
                    height: '25px',
                    width: '25px',
                  }}
                />
              </div>
            ),
          },
          {
            Header: '',
            accessor: '',
            maxWidth: 300,
            Cell: (props) => (
              <div style={{ textAlign: 'center' }}>
                {
                  <input
                    type="button"
                    value="Cancel"
                    onClick={(event) => {
                      this.deleteFieldRoute(props.original.id);
                      event.stopPropagation();
                    }}
                  />
                }
              </div>
            ),
          },
        ]}
        defaultSorted={[
          {
            id: 'created_date',
            desc: false,
          },
        ]}
      />
    );
  }
}
