import React from 'react';
import PropTypes from 'prop-types';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Polygon,
  Polyline,
  Marker,
} from 'react-google-maps';
import pin_available from '../images/pin-available.png';
import pin_damaged_fixed from '../images/pin-damaged-fixed.png';
import pin_damaged_collected from '../images/pin-damaged-collected.png';
import pin_damaged from '../images/pin-damaged.png';
import pin_emergency_pickup from '../images/pin-emergency-pickup.png';
import pin_inaccessible from '../images/pin-inaccessible.png';
import pin_low_battery from '../images/pin-low-battery.png';
import pin_missing from '../images/pin-missing.png';
import pin_picked_up from '../images/pin-picked-up.png';
import pin_pickup_requested from '../images/pin-pickup-requested.png';
import pin_reserved from '../images/pin-reserved.png';
import pin_riding from '../images/pin-riding.png';
import pin_very_low_battery from '../images/pin-low-battery.png';

const { MarkerWithLabel } = require('react-google-maps/lib/components/addons/MarkerWithLabel');

const Markers = (props) => {
  const labelSize = { width: 38 };
  const routeColors = ['blue', 'green', 'purple', 'red'];
  let startsArray = [];
  let endsArray = [];
  let i = 0;
  if (props.routeStarts && props.routeStarts.length > 0) {
    startsArray = props.routeStarts.map(function (x) {
      i++;
      return {
        lat: x[0].lat,
        lng: x[0].lng,
        id: i,
      };
    });
  }
  let z = 0;
  if (props.routeEnds && props.routeEnds.length > 0) {
    endsArray = props.routeEnds.map(function (x) {
      z++;
      return {
        lat: x[0].lat,
        lng: x[0].lng,
        id: z,
      };
    });
  }
  const start_location = { lat: props.places[0].location.lat, lng: props.places[0].location.lng };
  const done_color = '#2CC233';
  const not_done_color = '#F19938';
  const route_color = '#756FDE';
  return (
    <GoogleMap defaultZoom={props.zoom} defaultCenter={props.center}>
      {props.places !== undefined &&
        props.places.map((place, index) => {
          let icon_image;
          switch (place.status) {
            case 'emergency_pickup':
              icon_image = pin_emergency_pickup;
              break;
            case 'inaccessible':
              icon_image = pin_inaccessible;
              break;
            case 'low_battery':
              icon_image = pin_low_battery;
              break;
            case 'very_low_battery':
              icon_image = pin_very_low_battery;
              break;
            case 'reserved':
              icon_image = pin_reserved;
              break;
            case 'available':
              icon_image = pin_available;
              break;
            case 'picked_up':
              icon_image = pin_picked_up;
              break;
            case 'damaged':
              icon_image = pin_damaged;
              break;
            case 'pickup_requested':
              icon_image = pin_pickup_requested;
              break;
            case 'damaged_fixed':
              icon_image = pin_damaged_fixed;
              break;
            case 'missing':
              icon_image = pin_missing;
              break;
            case 'riding':
              icon_image = pin_riding;
              break;
            case 'damaged_collected':
              icon_image = pin_damaged_collected;
              break;
            default:
              icon_image = pin_available;
              break;
          }
          let name_background = '';
          place.has_active_ticket
            ? (name_background = not_done_color)
            : (name_background = done_color);
          return (
            <MarkerWithLabel
              labelStyle={{
                textAlign: 'center',
                width: labelSize.width + 'px',
                backgroundColor: name_background,
                fontSize: '10px',
                borderRadius: '8px',
                color: 'white',
                fontWeight: 'bold',
                padding: '2px',
              }}
              labelClass="map-label"
              labelAnchor={{ x: 21, y: -1 }}
              key={index}
              position={{ lat: place.location.lat, lng: place.location.lng }}
              optimized="false"
              zIndex={place.zindex}
              icon={{
                url: icon_image,
                anchor: new window.google.maps.Point(21, 40),
                scaledSize: { width: 40, height: 80 },
              }}>
              <span>{place.vehicle_name}</span>
            </MarkerWithLabel>
          );
        })}
      {startsArray !== undefined &&
        startsArray.map((place, index) => {
          return (
            <Marker
              key={`start_${index}`}
              position={{ lat: place.location.lat, lng: place.location.lng }}
              options={{ icon: 'http://maps.google.com/mapfiles/ms/icons/blue.png' }}
            />
          );
        })}
      {endsArray !== undefined &&
        endsArray.map((place, index) => {
          return (
            <Marker
              key={`end_${index}`}
              position={{ lat: place.location.lat, lng: place.location.lng }}
              options={{ icon: 'http://maps.google.com/mapfiles/ms/icons/red.png' }}
            />
          );
        })}
      {props.routePlaces !== undefined &&
        props.routePlaces.map((line, index) => {
          i++;
          return (
            <Polyline
              key={`line_${index}`}
              path={line}
              options={{
                strokeColor: routeColors[i],
                strokeOpacity: 1,
                strokeWeight: 2,
                icons: [
                  {
                    icon: 'hello',
                    offset: '0',
                    repeat: '10px',
                  },
                ],
              }}
            />
          );
        })}
      {props.restrictedAreasGrey !== undefined &&
        props.restrictedAreasGrey.map((rArea, index) => {
          return (
            <Polygon
              key={`polygon_${index}`}
              path={rArea}
              options={{
                fillColor: '#040404',
                fillOpacity: 0.5,
                strokeColor: '#040404',
                strokeOpacity: 1,
                strokeWeight: 1,
              }}
            />
          );
        })}
      {props.restrictedAreasRed !== undefined &&
        props.restrictedAreasRed.map((rArea, index) => {
          return (
            <Polygon
              key={`polygon_${index}`}
              path={rArea}
              options={{
                fillColor: '#903020',
                fillOpacity: 0.6,
                strokeColor: '#090302',
                strokeOpacity: 1,
                strokeWeight: 1,
              }}
            />
          );
        })}
      {props.restrictedAreasGrey !== undefined &&
        props.restrictedAreasPurple.map((rArea, index) => {
          return (
            <Polygon
              key={`polygon_${index}`}
              path={rArea}
              options={{
                fillColor: '#552090',
                fillOpacity: 0.5,
                strokeColor: '#552090',
                strokeOpacity: 1,
                strokeWeight: 1,
              }}
            />
          );
        })}
      return (
      <Polygon
        path={props.mapZoneBoundary}
        options={{
          fillColor: 'grey',
          fillOpacity: 0.1,
          strokeColor: 'purple',
          strokeOpacity: 1,
          strokeWeight: 3,
        }}
      />
      <Polyline
        path={props.routePlaces}
        options={{
          strokeColor: route_color,
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />
      <MarkerWithLabel
        labelStyle={{
          textAlign: 'center',
          width: labelSize.width + 'px',
          backgroundColor: '#2CC233',
          fontSize: '12px',
          borderRadius: '8px',
          color: 'white',
          fontWeight: 'bold',
          padding: '2px',
        }}
        labelClass="map-label"
        labelAnchor={{ x: 21, y: 60 }}
        key="1"
        position={start_location}
        optimized="false"
        icon={{
          url: 'icon_image',
          anchor: new window.google.maps.Point(21, 40),
          scaledSize: { width: 40, height: 80 },
        }}>
        <span>{'START'}</span>
      </MarkerWithLabel>
      );
    </GoogleMap>
  );
};

Markers.propTypes = {
  center: PropTypes.object,
  mapZoneBoundary: PropTypes.array,
  places: PropTypes.array,
  restrictedAreasGrey: PropTypes.array,
  restrictedAreasPurple: PropTypes.array,
  restrictedAreasRed: PropTypes.array,
  routeEnds: PropTypes.array,
  routePlaces: PropTypes.array,
  routeStarts: PropTypes.array,
  zoom: PropTypes.number,
};

export default withScriptjs(withGoogleMap(Markers));
